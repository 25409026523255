import React from "react";
import ModalImage from "react-modal-image";
import Page from "../../components/layouts/page";
import ExportDocument1 from "../../images/export-document.jpeg";

export default function ExportDocumentSystem() {
    return (
        <Page>
            <section className="section bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6"
                            data-sal="fade"
                            data-sal-delay="200"
                            data-sal-duration="700"
                            data-sal-easing="ease">
                            <div className="skills-img me-0 me-lg-5">
                                <ModalImage small={ExportDocument1} large={ExportDocument1} alt="Inventory System" className="img-fluid rounded shadow-sm"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="section-head mt-5 mt-lg-0"
                                data-sal="slide-up"
                                data-sal-delay="200"
                                data-sal-duration="600"
                                data-sal-easing="ease">
                                <span className="pre-title small position-relative fw-bold text-dark text-uppercase">Web Application</span>
                                <h2 className="section-title text-dark mt-3">Export Document System</h2>
                                <p className="section-subtitle mt-3">Export documents for employees.</p>
                                <ul className="check-list list-unstyled mb-0">
                                    <li>Export different type of documents.</li>
                                    <li>Add, update or delete employees.</li>
                                </ul>
                            </div>
                            <div className="skills-content mt-2 me-0 me-md-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-4"
                                            data-sal="slide-left"
                                            data-sal-delay="200"
                                            data-sal-duration="600"
                                            data-sal-easing="ease">
                                            <div className="clearfix">
                                                <span className="d-block w-50 float-start fw-bold small">CodeIgniter</span>
                                                <span className="d-block w-50 float-end fw-bold small text-end">100%</span>
                                            </div>
                                            <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                            </div>
                                        </div>
                                        <div className="mt-4"
                                            data-sal="slide-left"
                                            data-sal-delay="200"
                                            data-sal-duration="700"
                                            data-sal-easing="ease">
                                            <div className="clearfix">
                                                <span className="d-block w-50 float-start fw-bold small">Vanilla JS</span>
                                                <span className="d-block w-50 float-end fw-bold small text-end">100%</span>
                                            </div>
                                            <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                            </div>
                                        </div>
                                        <div className="mt-4"
                                            data-sal="slide-left"
                                            data-sal-delay="200"
                                            data-sal-duration="800"
                                            data-sal-easing="ease">
                                            <div className="clearfix">
                                                <span className="d-block w-50 float-start fw-bold small">MySQL</span>
                                                <span className="d-block w-50 float-end fw-bold small text-end">100%</span>
                                            </div>
                                            <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Page>
    );
}
